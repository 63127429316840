<template>
  <div>
    <form @submit.prevent="handleValidate">
      <b-row>
        <b-col class="border-right">
          <basic-input
            required
            label="Mã lộ trình"
            name="code"
            v-validate="{
              required: true,
              alpha_num: true,
              max: 50,
              valid_code: validCode,
            }"
            :state="validateState('code')"
            :invalidFeedback="errors.first('code')"
            data-vv-as="Mã lộ trình"
            placeholder="Nhập mã lộ trình"
            maxlength="50"
            v-model="form.code"
          />
          <basic-input
            required
            label="Tên lộ trình"
            name="name"
            v-validate="'required|max:100'"
            :state="validateState('name')"
            :invalidFeedback="errors.first('name')"
            data-vv-as="Tên lộ trình"
            placeholder="Nhập tên lộ trình"
            maxlength="100"
            v-model="form.name"
          />
          <basic-select
            v-validate="'required'"
            :required="true"
            label="Trạng thái"
            placeholder="--- Chọn trạng thái ---"
            name="status"
            :solid="false"
            :allowEmpty="true"
            :state="validateState('status')"
            :invalidFeedback="errors.first('status')"
            value-label="text"
            track-by="value"
            disabled
            :options="statuses"
            :value.sync="form.status"
            changeValueByObject
          />
          <basic-radio
            v-validate="{
              required: true,
            }"
            v-model="option"
            :options="options"
            :state="validateState('option')"
            :invalidFeedback="errors.first('option')"
            required
            name="option"
            data-vv-as="Tùy chọn"
            inline
            :disableRadio="isUpdate"
          />
          <basic-select
            v-validate="'required'"
            :required="true"
            label="Loại lộ trình"
            name="scheduleType"
            :solid="false"
            :allowEmpty="false"
            :state="validateState('scheduleType')"
            :invalidFeedback="errors.first('scheduleType')"
            value-label="text"
            track-by="value"
            :options="scheduleTypes"
            :value.sync="form.scheduleType"
            changeValueByObject
            :disabled="isUpdate"
            @update:value="selectedScheduleType"
          />
          <basic-text-area
            label="Mô tả lộ trình"
            placeholder="Nhập mô tả lộ trình"
            :value.sync="form.description"
            maxlength="500"
            name="description"
            v-validate="'max:500'"
            :state="validateState('description')"
            :invalidFeedback="errors.first('description')"
          />
        </b-col>
        <b-col>
          <div v-if="isMovementExercises" class="mb-5">
            <div class="image-upload">
              <v-file-input
                hide-input
                v-model="file"
                accept="image/png, image/jpeg"
                class="icon-edit"
                @change="onUpload"
                prepend-icon="mdi-pencil-outline"
                truncate-length="15"
              ></v-file-input>
              <v-img :src="preview" :aspect-ratio="2 / 1"></v-img>
            </div>
            <div class="error text-error">
              <p v-if="showImageError">Hình ảnh lộ trình là bắt buộc</p>
              <p v-if="!isImageValid" class="mb-1">
                Kích thước ảnh tối đa nhỏ hơn 512kb
              </p>
              <p v-if="!isImage" class="mb-1">
                Định dạng ảnh phải là JPG,PNG, JPEG
              </p>
            </div>
            <label
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div class="font-weight-bolder">
                Hình ảnh lộ trình, tỷ lệ 2:1
                <span class="text-danger">*</span>
              </div>
              <span class="d-block font-italic">
                Tối đa 512kb, kích thước đề xuất 640:320px</span
              ></label
            >
          </div>
          <basic-select
            v-if="isDaySchedule"
            v-validate="'required'"
            :required="true"
            label="Số ngày"
            placeholder="--- Chọn số ngày cho lộ trình ---"
            name="date"
            :solid="false"
            :allowEmpty="false"
            :state="validateState('date')"
            :invalidFeedback="errors.first('date')"
            value-label="text"
            track-by="value"
            :options="days"
            :value.sync="form.day"
            changeValueByObject
            data-vv-as="Số ngày"
          />
          <basic-select
            v-else
            v-validate="'required'"
            :required="true"
            label="Số tuần"
            placeholder="--- Chọn số tuần cho lộ trình ---"
            name="week"
            :solid="false"
            :allowEmpty="false"
            :state="validateState('week')"
            :invalidFeedback="errors.first('week')"
            value-label="text"
            track-by="value"
            :options="weeks"
            :value.sync="form.week"
            changeValueByObject
            data-vv-as="Số tuần"
          />
          <template v-if="isMovementExercises">
            <basic-select
              v-validate="{
                required: true,
                valid_free_day: form.day ? form.day.value : 1,
              }"
              :required="true"
              label="Số ngày học free"
              placeholder="--- Chọn số ngày học free ---"
              name="dateFree"
              :solid="false"
              :allowEmpty="false"
              :state="validateState('dateFree')"
              :invalidFeedback="errors.first('dateFree')"
              value-label="text"
              track-by="value"
              :options="freeDays"
              changeValueByObject
              data-vv-as="Số ngày học free"
              v-model="form.openDay"
              @update:value="selectedFreeDay"
            />
            <div class="form-group">
              <label>Từ khóa</label>
              <multiselect
                :options="tags"
                placeholder="--- chọn các từ khóa cho lộ trình ---"
                tag-placeholder="Thêm từ khóa"
                select-label=""
                selected-label="Đã chọn"
                deselect-label="Bỏ chọn"
                label="text"
                track-by="value"
                v-model="form.selectTags"
                multiple
                taggable
                @tag="onAddTag"
              />
            </div>
            <basic-select
              v-validate="'required'"
              :required="true"
              label="Cường độ"
              placeholder="--- Chọn cường độ ---"
              name="intensity"
              :solid="false"
              :allowEmpty="false"
              :state="validateState('intensity')"
              :invalidFeedback="errors.first('intensity')"
              value-label="text"
              track-by="value"
              :options="exerciseIntensities"
              :value.sync="form.exerciseIntensity"
              changeValueByObject
              data-vv-as="Cường độ"
              @update:value="$validator.reset({ name: 'intensity' })"
            />
          </template>
          <basic-select
            label="Gói dịch vụ"
            placeholder="--- Chọn gói dịch vụ ---"
            name="package"
            :solid="false"
            :allowEmpty="true"
            value-label="text"
            track-by="value"
            :options="packages"
            :value.sync="form.selectedPackages"
            changeValueByObject
            multiple
            @update:value="selectPackage"
          />
          <basic-select
            :label="`${
              option === 1 ? 'Ngày' : 'Tuần'
            } bắt đầu áp dụng lộ trình`"
            :placeholder="`--- Chọn ${
              option === 1 ? 'ngày' : 'tuần'
            } bắt đầu áp dụng lộ trình ---`"
            name="startWeek"
            :solid="false"
            :allowEmpty="false"
            value-label="text"
            track-by="value"
            :options="startOtps"
            :value.sync="form.startSchedule"
            changeValueByObject
            :required="
              form.selectedPackages && form.selectedPackages.length
                ? true
                : false
            "
            v-validate="{
              required:
                form.selectedPackages && form.selectedPackages.length
                  ? true
                  : false,
            }"
            :state="validateState('startWeek')"
            :invalidFeedback="errors.first('startWeek')"
            :data-vv-as="`${
              option === 1 ? 'Ngày' : 'Tuần'
            } bắt đầu áp dụng lộ trình`"
            @update:value="$validator.reset({ name: 'startWeek' })"
          />
        </b-col>
      </b-row>

      <b-row class="border-top">
        <b-col class="text-right">
          <b-button class="btn mr-2" type="button" @click="cancel">
            Hủy
          </b-button>
          <template v-if="isUpdate">
            <b-button
              v-if="showBtnActive"
              class="btn btn-primary mr-2"
              type="button"
              @click="$emit('change-status', 1)"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
              </span>
              Active
            </b-button>
            <b-button
              v-else
              class="btn btn-danger mr-2"
              type="button"
              @click="$emit('change-status', 0)"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
              </span>
              Inactive
            </b-button>
          </template>
          <b-button class="btn btn-success" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>
<script>
import differenceWith from 'lodash/differenceWith';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import Multiselect from 'vue-multiselect';
import { SCHEDULE_TYPES } from '../../../../core/plugins/constants';
export default {
  name: 'Form',
  components: {
    Multiselect,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      validCode: false,
      file: null,
      preview: null,
      isImageValid: true,
      isImage: true,
      tags: [],
      form: {
        selectTags: [],
        status: {
          text: 'Draft',
          value: '2',
        },
        exerciseIntensity: null,
        day: {
          text: '1',
          value: '1',
        },
        week: {
          text: '1',
          value: '1',
        },
        openDay: {
          text: '0',
          value: '0',
        },
        code: null,
        name: null,
        description: null,
        selectedPackages: [],
        startSchedule: null,
        scheduleType: {},
      },
      statuses: [
        {
          text: 'Draft',
          value: '2',
        },
        {
          text: 'Active',
          value: '1',
        },
        {
          text: 'Inactive',
          value: '0',
        },
      ],
      scheduleTypes: [],
      originalScheduleTypes: [
        {
          text: 'Bài tập vận động',
          value: SCHEDULE_TYPES.MOVEMENT_EXERCISES,
          type: 1,
        },
        {
          text: 'Hoạt động tự do',
          value: SCHEDULE_TYPES.FREE,
          type: 1,
        },
        {
          text: 'Hoạt động gắn với KPI',
          value: SCHEDULE_TYPES.KPI,
          type: 1,
        },
        {
          text: 'Hoạt động chung',
          value: SCHEDULE_TYPES.COMMON,
          type: 2,
        },
        {
          text: 'Bài học',
          value: SCHEDULE_TYPES.LESSON,
          type: 2,
        },
      ],
      exerciseIntensities: [],
      newTagsOptions: [],
      originalTags: [],
      showImageError: false,
      days: Array.from({ length: 200 }, (_, i) => {
        return {
          text: `${i + 1}`,
          value: `${i + 1}`,
        };
      }),
      weeks: Array.from({ length: 50 }, (_, i) => {
        return {
          text: `${i + 1}`,
          value: `${i + 1}`,
        };
      }),
      freeDays: Array.from({ length: 201 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      }),
      option: 1,
      options: [
        { name: 'Lộ trình ngày', value: 1 },
        {
          name: 'Lộ trình tuần',
          value: 2,
        },
      ],
      packages: [],
      startOtps: [],
    };
  },
  watch: {
    data: {
      handler() {
        this.setupData();
      },
      // deep: true,
    },
    option(val) {
      this.scheduleTypes = this.originalScheduleTypes.filter(
        (el) => el.type === val,
      );
      if (this.isUpdate) return;
      if (val === 1) {
        this.form.week = null;
        this.form.selectedPackages = null;
        this.startSchedule = null;
        return (this.form.scheduleType = {
          text: 'Bài tập vận động',
          value: SCHEDULE_TYPES.MOVEMENT_EXERCISES,
          type: 1,
        });
      }
      this.preview = null;
      this.file = null;
      this.form.day = null;
      this.form.openDay = null;
      this.form.startSchedule = null;
      this.form.selectedPackages = null;
      this.form.exerciseIntensity = null;

      return (this.form.scheduleType = {
        text: 'Hoạt động chung',
        value: SCHEDULE_TYPES.COMMON,
        type: 2,
      });
    },
    'form.scheduleType': {
      handler() {
        this.generateStatOpts();
        this.getPackageList();
      },
      deep: true,
    },
    'form.day': {
      handler() {
        this.form.selectedPackages?.length && this.generateStatOpts();
      },
      deep: true,
    },
    'form.week': {
      handler() {
        this.form.selectedPackages?.length && this.generateStatOpts();
      },
      deep: true,
    },
    'form.code': function (val) {
      const code = this.data.code;
      if (!val || code === val) return (this.validCode = false);
      this.checkDuplicateCode(val);
    },
  },
  computed: {
    showBtnActive() {
      if ([0, 2].includes(this.data?.status)) return true;
      return false;
    },
    getErrorCheckFreeDay() {
      return 'test';
    },
    isMovementExercises() {
      return this.form.scheduleType.value === SCHEDULE_TYPES.MOVEMENT_EXERCISES;
    },
    isDaySchedule() {
      return this.option === 1;
    },
  },
  created() {
    this.setupData();
  },
  methods: {
    setupData() {
      if (this.data) {
        const movementdetail = { ...this.data };
        this.form.code = movementdetail.code;
        this.form.name = movementdetail.name;
        this.form.description = movementdetail.description;
        this.form.selectedPackages = movementdetail.selectedPackages || [];
        this.tags = movementdetail.tagList || [];
        this.exerciseIntensities = movementdetail.exerciseIntensityList || [];
        this.packages = movementdetail.packageList || [];
        this.preview = movementdetail.image?.url || null;
        movementdetail.type && (this.option = movementdetail.type);

        this.scheduleTypes = this.originalScheduleTypes.filter(
          (el) => el.type === this.option,
        );

        this.form.scheduleType = this.scheduleTypes.find(
          (el) => el.value === movementdetail.classify,
        ) || {
          text: 'Bài tập vận động',
          value: 1,
        };

        if (this.isUpdate || this.isCopy) {
          this.isUpdate &&
            (this.form.status = this.setSelectedItems(
              movementdetail.status,
              this.statuses,
            ));

          if (this.option === 2) {
            this.form.week = this.setSelectedItems(
              movementdetail.week,
              this.weeks,
            );

            movementdetail.weekStart !== null &&
              (this.form.startSchedule = {
                text: `${movementdetail.weekStart}`,
                value: `${movementdetail.weekStart}`,
              });
          } else {
            this.form.day = this.setSelectedItems(
              movementdetail.day,
              this.days,
            );
            movementdetail.dayStart !== null &&
              (this.form.startSchedule = {
                text: `${movementdetail.dayStart}`,
                value: `${movementdetail.dayStart}`,
              });
          }

          if (movementdetail.classify === SCHEDULE_TYPES.MOVEMENT_EXERCISES) {
            this.form.openDay = this.setSelectedItems(
              movementdetail.openDay,
              this.freeDays,
            );
            const exerciseIntensity = {
              text: movementdetail.exerciseIntensity.name,
              value: movementdetail.exerciseIntensity.id,
            };
            this.form.exerciseIntensity = exerciseIntensity;

            this.form.selectTags = movementdetail.selectTags.map((el) => ({
              text: el.name,
              value: el.id,
            }));
            this.originalTags = [...this.form.selectTags];
            this.preview = movementdetail.image.url;
          }
        }
      }
    },
    setSelectedItems(value, array) {
      return array.find((el) => Number(el.value) == value);
    },
    onUpload() {
      if (!this.file) return;
      this.isImage = ['image/jpeg', 'image/png'].includes(this.file.type);
      this.isImageValid = this.file.size <= 512000;
      if (this.isImage && this.isImageValid) {
        this.showImageError = false;
        return (this.preview = URL.createObjectURL(this.file));
      }
      return (this.preview = null);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async handleValidate() {
      await (this.validCode = false);
      this.$validator.validateAll().then((result) => {
        if (
          this.form.scheduleType.value === SCHEDULE_TYPES.MOVEMENT_EXERCISES
        ) {
          if (!this.preview) {
            this.showImageError = true;
          }
          if (result && this.preview) {
            this.showImageError = false;
            this.submit();
          }
          return;
        }

        result && this.submit();
      });
    },
    submit() {
      let params = {
        code: this.form.code,
        name: this.form.name,
        status: Number(this.form.status.value),
        classify: this.form.scheduleType.value,
        type: this.option,
      };

      if (this.form.selectedPackages && this.form.selectedPackages.length) {
        params['packages'] = this.form.selectedPackages.map((el) => el.value);
      }

      if (this.form.description) {
        params['description'] = this.form.description;
      }

      switch (this.form.scheduleType.value) {
        case SCHEDULE_TYPES.MOVEMENT_EXERCISES:
          params['openDay'] = Number(this.form.openDay.value);
          params['exerciseIntensityId'] = this.form.exerciseIntensity.value;
          params['roadmapTagMappings'] = this.getTagsParams();

          if (this.form.startSchedule) {
            params['dayStart'] = Number(this.form.startSchedule.value);
          }

          if (this.form.day) {
            params['day'] = Number(this.form.day.value);
          }
          break;
        case SCHEDULE_TYPES.FREE:
        case SCHEDULE_TYPES.KPI:
          params = {
            ...params,
            day: Number(this.form.day.value),
          };
          if (this.form.startSchedule) {
            params['dayStart'] = Number(this.form.startSchedule.value);
          }
          break;
        case SCHEDULE_TYPES.COMMON:
        case SCHEDULE_TYPES.LESSON:
          params = {
            ...params,
            week: Number(this.form.week.value),
          };
          if (this.form.startSchedule) {
            params['weekStart'] = Number(this.form.startSchedule.value);
          }
          break;

        default:
          break;
      }
      if (this.file) {
        params['file'] = this.file;
      }
      this.$emit('submit', params);
    },
    cancel() {
      this.$emit('cancel');
    },
    onAddTag(tag) {
      const newTag = {
        text: tag,
        value: this.$uuid.v1(),
      };
      this.tags.push(newTag);
      this.form.selectTags.push(newTag);
      this.newTagsOptions.push(newTag);
    },
    getTagsParams() {
      let tagParams = [];
      const tagParamsPush = (array, status, type = 1) => {
        array.forEach((tag) => {
          let params = {
            ...(type && {
              agendaId: this.id,
              tagId: tag.value,
            }),
            tag: {
              name: tag.text,
              type: 2,
            },
            modelStatus: status,
          };
          tagParams.push(this.removeEmpty(params));
        });
      };

      //Get common tags of two tags array => Status: 0 (Unchanged)
      let commonTags = intersectionWith(
        this.originalTags,
        this.form.selectTags,
        isEqual,
      );
      //Get tags only in original tags array => Status: 2 (Deleted)
      let uniqueOriginalTags = differenceWith(
        this.originalTags,
        this.form.selectTags,
        isEqual,
      );
      //Get tags only in current tags array
      let uniqueCurrentTags = differenceWith(
        this.form.selectTags,
        this.originalTags,
        isEqual,
      );
      let existedTags = differenceWith(
        uniqueCurrentTags,
        this.newTagsOptions,
        isEqual,
      ); // Status: 3 (new mapping)
      let createdTags = intersectionWith(
        uniqueCurrentTags,
        this.newTagsOptions,
        isEqual,
      ); // Status: 3 (new tag)

      tagParamsPush(commonTags, 0);
      tagParamsPush(uniqueOriginalTags, 2);
      tagParamsPush(existedTags, 3);
      tagParamsPush(createdTags, 3, 0);

      return tagParams;
    },
    async selectedFreeDay(value) {
      this.form.openDay = value;
      await this.$validator.validate('dateFree');
    },
    async getPackageList(loading = true) {
      try {
        loading && this.$emit('set-loading', true);
        const { meta, data, error } = await this.$api.get(
          '/Agenda/SearchAvailableAgendaPackage',
          {
            params: {
              classify: this.form.scheduleType.value,
            },
          },
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.packages = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        loading && this.$emit('set-loading', false);
      }
    },
    async selectPackage(value) {
      if (value && value.length) {
        this.generateStatOpts();
      } else {
        this.startOtps = [];
      }
      this.form.startSchedule = null;
      await this.$validator.reset({ name: 'package' });
    },
    generateStatOpts() {
      if (!this.form.selectedPackages || !this.form.selectedPackages.length)
        return (this.startOtps = []);
      switch (this.form.scheduleType.value) {
        case SCHEDULE_TYPES.MOVEMENT_EXERCISES:
          this.startOtps = Array.from({ length: 10 }, (_, i) => {
            return {
              text: `${i + 1}`,
              value: `${i + 1}`,
            };
          });
          break;
        case SCHEDULE_TYPES.FREE:
        case SCHEDULE_TYPES.KPI:
          this.startOtps = Array.from({ length: 7 }, (_, i) => {
            return {
              text: `${i + 1}`,
              value: `${i + 1}`,
            };
          });
          break;

        default:
          this.startOtps = Array.from({ length: 6 }, (_, i) => {
            return {
              text: `${i}`,
              value: `${i}`,
            };
          });
          break;
      }
    },
    selectedScheduleType(selected) {
      switch (selected.value) {
        case SCHEDULE_TYPES.KPI:
        case SCHEDULE_TYPES.FREE:
          this.preview = null;
          this.file = null;
          this.form.openDay = null;
          this.form.exerciseIntensity = null;
          break;

        default:
          break;
      }
    },
    async checkDuplicateCode(code) {
      try {
        const { meta, error, data } = await this.$api.get(
          '/Agenda/CheckDuplicateCode',
          {
            params: {
              code,
            },
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.validCode = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.image-upload {
  width: 240px;
  height: 120px;
  background-color: #f3f6f9;
  box-shadow: 0px 4px 4px 0px #000000 10%;
  position: relative;
  margin: auto;
}
.text-error {
  color: #ff5756;
  text-align: center;
  margin-top: 20px;
}
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 18px);
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
.btn-danger {
  background-color: #ff5756 !important;
  border-color: #ff5756 !important;
  color: #fff !important;
}
</style>

<style lang="scss">
.radio-inline {
  column-gap: 60px;
}
</style>
